import { FragmentDefiner } from "../../../../../GlobalHooks/useReadFragment";

import { PropComponentMode } from "../../../PropertyInterfaces";
import { gql } from "@apollo/client/core";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { ModalState } from "../../TextForm/type";
import { useEffect } from "react";
import { REGISTER_MUTATION, USER_EXISTS_QUERY, UPDATE_USER_MUTATION } from "shared-components/Components/User/User.querys";
import { useUser } from "shared-components/Components/User/User.hook";
import cookie from "js-cookie";
import getConfig from "next/config";

const REQUEST_PHONE_MUTATION = gql`
	mutation request_phone_mutation(
		$property_id: Int!
		$type: PropEntityType
		$email: String
		$name: String
		$phone: String
	) {
		requestPhoneAgent(
			property_id: $property_id
			isWpp: false
			type: $type
			email: $email
			name: $name
			phone: $phone
		) {
			name
			phone
		}
	}
`;

export interface OwnerPhoneInterface {
	id: number | string;
	type?: "callButton" | "viewPhone";
	mode: PropComponentMode;
	disabled?: boolean;
	property_info?: any;
	seller?: boolean;
	queryRouter?: string;
	icon?: boolean;
	btnType?: "text" | "default";
	onPhoneClick?: () => void;
	forceState?: ModalState;
	owner?: any;
	isProject?: boolean;
}

export const FRAGMENT_MASKED_PHONE = new FragmentDefiner(
	"Property",
	`
	title
	seller {
		id
		name
		masked_phone
	}
    owner {
        id
        name
        masked_phone
    }
    operation_type {
    	id
    	name
    }
`
);

const queryMaskedPhone = gql`
	query propery($id: ID) {
		property(id: $id) {
			title
			seller {
				id
				name
				masked_phone
			}
			owner {
				id
				name
				masked_phone
			}
			operation_type {
				id
				name
			}
		}
	}
`;

export const usePhoneButton = ({
	property_id,
	isProject,
	seller = false,
	email = null,
	phone = null,
	name = null,
}) => {
	const [mutation, { loading, data: mutationData, called }] = useMutation(
		REQUEST_PHONE_MUTATION,
		{
			onError: error => { },
		}
	);

	const { data } = useQuery(queryMaskedPhone, { variables: { id: property_id } });

	const [
		registerMutation,
		{ loading: registerLoading, data: dataRegisterMutation },
	] = useMutation(REGISTER_MUTATION);
	const [updateUserMutation, { loading: updateLoading }] = useMutation(UPDATE_USER_MUTATION);
	const updateUser = ({ name, phone }) => {
		return updateUserMutation({ variables: { name, phone } });
	};
	const { user, isLoggedIn } = useUser();
	const [
		userExistsQuery,
		{ loading: userExistsLoading, data: dataUserExist, error },
	] = useLazyQuery(USER_EXISTS_QUERY, {
		fetchPolicy: "network-only",
		onError: error => {
			if (error.message.includes("no existe")) {
				registerMutation({
					variables: {
						email: email,
						name: name,
					},
				})
					.then(({ data }) => {
						updateUser({ name: name, phone: phone }).then(() => {
							user.refetch();
						});
					})
					.catch(error => {
						console.error(error);
					});
			}
		},
	});
	const { loginBasePath } = getConfig().publicRuntimeConfig;

	const refreshAuth = data => {
		cookie.set("frontend_token", data.access_token, {
			expires: 365,
			domain: loginBasePath,
			path: "/",
		});
		localStorage.setItem("user_md5", data.user_md5);
		user.refetch();
	};

	useEffect(() => {
		if (dataRegisterMutation) {
			refreshAuth(dataRegisterMutation.registerIndividual);
		}
	}, [dataRegisterMutation]);

	//nota: como el telefono se guarda en un state, no en cache de apolo. cada ver teléfono va a hacer la mutation por separado
	const viewPhone = async () => {
		if (!email || !phone) {
			return { error: "Email y Telefono no pueden ser vacios." };
		}
		return await mutation({
			variables: {
				property_id,
				type: isProject ? "PROJECT" : "PROPERTY",
				email,
				name,
				phone,
			},
		});
	};

	let masked_phone = seller
		? data?.property?.seller?.masked_phone
		: data?.property?.owner?.masked_phone;
	masked_phone = masked_phone || data?.property?.owner?.masked_phone;

	const filteredPhones = new Set(
		mutationData?.requestPhoneAgent?.map(item => item.phone).filter(phone => phone)
	);
	const phones = Array.from(filteredPhones).join(";");

	return {
		viewPhone,
		owner_name: data?.property?.owner.name,
		masked_phone: masked_phone,
		prop_title: data && data.property ? data.property.title : "",
		operation_type_name:
			data && data?.property?.operation_type?.name ? data.property.operation_type.name : "",
		phone: phones,
		loading,
		called,
		userExistsQuery
	};
};

